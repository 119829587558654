import { register, getUserByUid } from '@/api/user'
import { getFirebaseUid } from '@/utils/firebase'
import { MYY_SOURCE_IDENTIFIER } from '@/settings.js'
var user = {
    // created() {
    //   if (getFirebaseUid() && !getUserId()) {

    //     this.createIfNotExist()
    //   }
    // },
    methods: {
        createIfNotExist() {
            return new Promise(resolve => {
                getFirebaseUid().then(uid => {
                    getUserByUid(uid)
                        .then(user => {
                            this.$store.dispatch('updateUser', user)
                            resolve(user)
                        })
                        .catch(error => {
                            if (error) {
                                // check if 404
                                register({
                                    uid: uid,
                                    source: {
                                        name: MYY_SOURCE_IDENTIFIER
                                    }
                                }).then(user => {
                                    this.$store.dispatch('updateUser', user)
                                    resolve(user)
                                })
                            }
                        })
                })
            })
        }
    }
}

export { user }
