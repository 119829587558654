import {
    isMobile,
    isMobileOnly,
    isTablet,
    isBrowser,
    osName,
    browserName,
    mobileVendor,
    fullBrowserVersion,
    osVersion
} from 'mobile-device-detect'

const getDeviceType = () => {
    if (isMobile && isMobileOnly) {
        return 'mobile'
    }

    if (isTablet) {
        return 'tablet'
    }

    if (isBrowser) {
        return 'browser'
    }

    return 'other'
}
const getDeviceData = () => {
    return {
        deviceType: getDeviceType(),
        osName: osName,
        browserName: browserName,
        mobileVendor: mobileVendor,
        browserVersion: fullBrowserVersion,
        osVersion: osVersion
    }
}

const entry = {
    first_name: null,
    last_name: null,
    email: null,
    date_of_birth: null,
    phone: null,
    street: null,
    suburb: null,
    city: null,
    state: null,
    postcode: null,
    terms: [],
    title: null,
    opt_in: [null, null],
    gender: null,
    retailer: null,
    device: getDeviceData()
}

export default entry
