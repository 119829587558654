<template>
  <div>
  
    <el-form
        ref="entry"
        :rules="entryRules"
        :label-position="labelPosition"
        :model="entry"
      >
        <el-row :gutter="20">
          <el-col :span="24" :sm="12">
            <el-form-item label="First name" prop="first_name">
              <el-input v-model="entry.first_name" placeholder="First name" />
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12">
            <el-form-item label="Last name" prop="last_name">
              <el-input v-model="entry.last_name" placeholder="Last name" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Email address" prop="email">
              <el-input v-model="entry.email" placeholder="Email address" />
              <div class="text-muted subtext" style="line-height:1rem"
                >Please ensure your email address is correct as this is how we
                will communicate with you regarding your entry. You should
                receive an email confirmation to confirm your entry has been
                received. If you do not receive this email, please check your
                junk mail.</div
              >
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24" :sm="12">
            <el-form-item label="Contact Number" prop="phone">
              <el-input v-model="entry.phone" placeholder="Phone" />
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12">
            <el-form-item label="State" prop="state">
              <el-select v-model="entry.state" placeholder="State">
                <el-option
                  v-for="state in states"
                  :key="state"
                  :label="state"
                  :value="state"
                  >{{ state }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        ref="receipt"
        :rules="rules"
        :show-message="showValidationMessage"
        :label-position="labelPosition"
        :model="receipt"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="list" label="Upload your receipt">
              <el-upload
                ref="upload"
                drag
                :action="uploadSettings.action"
                :list-type="uploadSettings.uploadListType"
                :auto-upload="uploadSettings.autoUpload"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :file-list="receipt.files"
                :multiple="uploadSettings.multiple"
                :on-exceed="handleExceed"
                :limit="uploadSettings.limit"
                :http-request="uploadRequest"
                :before-upload="beforeUpload"
                :on-success="onUploadSuccess"
                accept=".png,.jpeg,.jpg,.pdf"
              >
                <i :class="uploadIcon" />
                <div class="el-upload__text">
                  Drop file here or <em>click to upload</em>
                </div>
              </el-upload>
            </el-form-item>
            <el-alert
              v-if="uploadError"
              title="Error"
              type="error"
              :description="uploadError"
              :closable="false"
              class="mb-4"
            />
            <p class="subtext">
              Please ensure all transaction details are visible in the upload,
              including store, date of purchase and products purchased. The file
              size must be a maximum of 6MB and in one of the following formats:
              JPG, PNG or PDF. Please keep a copy of your receipt.
              <a href="#" @click.prevent="dialogVisible = true">Click here</a>
              for help uploading your receipt.
            </p>
          </el-col>
        </el-row>
      </el-form>

    <el-form
        ref="entryTerms"
        :rules="entryTermsRules"
        :label-position="labelPosition"
        :model="entry"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item class="mt-4"  required prop="terms" style="margin-bottom: 0">
              <el-checkbox v-model="entry.terms" name="type">
                <span class="text-danger">*</span> I am 18 years or older and
                submit my entry in accordance with the
                <router-link
                  :to="{ path: 'terms' }"
                  >Terms and Conditions</router-link
                >
                and
                <a
                  href="https://www.pepsico.com.au/privacy-policy"
                  target="_blank"
                  >Privacy Policy</a
                >.
              </el-checkbox>
            </el-form-item>            
          </el-col>
          <el-col>
            <el-form-item prop="opt_in" :span="24" style="margin-bottom: 0">
              <el-checkbox
                v-model="entry.opt_in[0]"
                name="opt_in"
                :label="opt_in"
              >Please tick this box if you would like to receive further information regarding our products and promotions.</el-checkbox
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-alert
          v-if="response.message"
          class="mt-2"
          :title="response.title"
          :type="response.type"
          :description="response.message"
          :closable="response.closable"
        />
      </el-form>
    <el-row :gutter="20" justify="center" type="flex" class="mt-5 pb-5">
      <el-col :sm="24" :md="12">
        <el-button
          :loading="loading"
          type="primary"
          class="submit-btn"
          :class="submitClass"
          style="width:100%"
          @click="submit"
        >{{ buttonLabels[uploadProgress] }}</el-button>
      </el-col>
    </el-row>
    <el-dialog title="How to upload" :visible.sync="dialogVisible" width="80%">
      <faqs />
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment-timezone'

import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'

import { getPreSignedUrl, upload, detectReceipt } from '@/api/receipt'
import { participate } from '@/api/activity'

import { entryRules,receiptRules, termsRules} from '@/utils/validators'
import entryModel from '@/models/entry'
import states from '@/models/states'
import {  MYY_OPTIN, PRIVACY_POLICY_URL } from '@/settings'


import { user } from '@/mixins/user'
import Faqs from './Faqs'

export default {
    components: {
        Faqs,
    },
    mixins: [user],
    data() {
        return {
            submitClass: '',
            activityId: null,
            loading: false,
            dialogVisible: false,
            labelPosition: 'top',
            bankLoading: false,
            opt_in: false,
            uploadSettings: {
                autoUpload: false,
                multiple: false,
                limit: 1,
                uploadListType: 'text',
                action: ''
            },
            uploadProgress: 'default',
            buttonLabels: {
                default: 'Submit',
                uploading: 'Uploading receipt. Please wait...',
                analysing: 'Uploading receipt. Please wait...',
                submitting: 'Submitting...'
            },
            receipt: {
                files: [],
                list: []
            },
            receiptData: {
                url: null,
                originalName: null,
                name: null,
                receiptId: null
            },
       
            showValidationMessage: false,
            response: {
                type: 'error',
                title: 'Error',
                message: null,
                closable: false
            },
          
            rules: receiptRules,

            autoUpload: false,
            uploadListType: 'picture',

            entry: JSON.parse(JSON.stringify(entryModel)),
          
            entryRules: entryRules,
            entryTermsRules: termsRules,
            states: states,
            genders: {
                Male: 'Mr',
                Female: 'Ms',
                Other: 'Other'

            },
            titles: {
                'Mr': 'Male',
                'Mrs': 'Female',
                'Ms': 'Female',
                'Other': 'Other'
            },
            uploadError: null,
            policy: PRIVACY_POLICY_URL
        }
    },
    computed: {
        ...mapGetters(['getFirebaseUid', 'getUserId','dob', 'routerQuery', 'activity']),
      
        uploadIcon() {
            return this.uploadSettings.uploadListType === 'picture' ||
        this.uploadSettings.uploadListType === 'text'
                ? 'el-icon-upload'
                : 'el-icon-plus'
        },


        paticipateData() {
            const optin = []
            // if (this.entry.opt_in.length > 0) {
            if (this.entry.opt_in[0]) {
                optin.push(MYY_OPTIN)
            }

            const extraData = Object.assign(Object.assign({}, this.entry), {
                date_of_birth: parseInt(
                    moment(this.entry.date_of_birth, 'DDMMYYYY').format('X')
                ),
                gender: this.titles[this.entry.title],
                street: this.entry.street,
                suburb: this.entry.suburb,
                state: this.entry.state,
                retailer: this.entry.retailer,

                opt_in: this.entry.opt_in,
                receipt: this.receiptData,
                timezone: moment.tz.guess()
            })
            extraData.other_data = {
                title: this.entry.title
            }

            const result = {
                activity_id: this.activity._id,
                user_id: this.getUserId,
                receipt_id: this.receiptData.receiptId
            }
            if (this.receipt.list.length) {
                result.extra_data = extraData
            } else {
                result.user_details = extraData
            }
            return result
        }
    },
    mounted() {
        EventBus.$on('updateDOB', this.updateDOB)
        this.updateDOB(this.dob)
    },
    created(){
        this.entry.retailer = this.$route.query.r
    },
    methods: {
        updateDOB(dob) {
            this.entry.date_of_birth = dob
        },
        handleChange(file, fileList) {
            this.uploadError = null
            if (this.uploadSettings.multiple) {
                this.uploadSettings.uploadListType = 'picture-card'
            }
            this.receipt.list = fileList
            this.response.message = null
        },
        handleRemove() {},
        beforeUpload(file) {
            const isLt6M = file.size / 1024 / 1024 < 6

            if (!isLt6M) {
                this.setError('Receipt size can not exceed 6MB.')
            }
            return isLt6M
        },

        async submit() {
            this.response.message = null
            
            Promise.all([
                this.$refs['entry'].validate().catch(() => { return  Promise.reject('Please check the entered fields.') }),
                this.$refs['entryTerms'].validate().catch(() => { return  Promise.reject( 'You must agree with terms and conditions.')  }),
                this.$refs['receipt'].validate().catch(() => { return  Promise.reject( 'Please select a receipt to upload.')  }),
            ]).then(() => {
                this.loading = true
                this.submitClass = 'loading'
                // only support single file upload for now
                this.$refs.upload.submit()
            }).catch(error => {
                this.setError(error)
            })
           
        },

        handleExceed() {
            this.uploadError = 'You can only upload ' + this.uploadSettings.limit + ' file.'
            return false
        },
        generateFilename(originalName) {
            return this.getFirebaseUid + '_' + Date.now() + '_'+ originalName
        },

        async uploadRequest(data) {
            try{
                await this.createIfNotExist()
                const filename = this.generateFilename(data.file.name)
                const url =  await getPreSignedUrl(filename)
                await upload(url, data.file)
                this.receiptData.url = url.split('?')[0]
                this.receiptData.name = filename
                this.receiptData.originalName = data.file.name

                // this.onUploadSuccess()
            }
            catch(error){
                this.setError(error)
                this.setReceiptData({})
            }
           
                
        },
        onUploadSuccess() {
            this.uploadProgress = 'analysing'
            detectReceipt({
                receipt_url: this.receiptData.url,
                activity_id: this.activity._id,
                user_id: this.getUserId
            }).then(response => {
                this.validateReceipt(response)
            })
                .catch((error) => this.setError(error.statusText ?? 'Receipt not detected. Please try again.'))
        },
        validateReceipt(response) {
            if (response.data.isReceipt === false) {
                this.setError('Receipt not detected. Please try again.')
                this.submitClass = ''
                return
            }
            this.setReceiptData(response)

            this.submitEntry()
        },
        submitEntry() {
            this.uploadProgress = 'submitting'
            participate(this.paticipateData)
                .then(response => {
                   
                    let query = {agegate: false}
                    if(response.instant_win_result){
                        const result = response?.instant_win_result?.entries[0]
                        query.w = result && result.is_win ? result.winner_id : null
                    }
                       
                    this.$store.dispatch('reset')
                    this.submitClass = 'complete'
                    this.submitClass = ''
                    this.setReceiptData({})
                    this.$router.push({ path: 'result', query: query, hash: '#result' })
                    
                })
                .catch(error => this.setError(error.data ?? error))
        },
      
        setError(errorMsg) {
            this.loading = false
            this.response.message = errorMsg
            this.uploadProgress = 'default'
            this.submitClass = ''
        },
        setReceiptData(response) {
            this.receiptData.receiptId = response._id
            this.receiptData.venue = response?.data?.formattedData
                ? response.data.formattedData.venue
                : null
        },
        getAddressData(addressData, placeResultData, id) {
            this.entry.street = placeResultData.name
            this.entry.suburb = addressData.locality
            this.entry.state = addressData.administrative_area_level_1
            this.entry.postcode = addressData.postal_code
            document.getElementById(id).value = placeResultData.name
           
        },
        onAddressChange(data) {
            this.entry.street = data
            this.validateField('street')
            this.validateField('state')
        },
        validateField(field) {
            this.$refs['entry'].validateField(field)
        },
      
    }
}
</script>

<style>
.el-checkbox__label {
  white-space: break-spaces;
}
.el-checkbox:last-of-type {
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 0.5em;
}
.subtext {
  font-size: 0.8rem;
  line-height: 1.2rem;
}
</style>
