<template>
  <div>

    <h6>Retailer receipt - upload</h6>
    <p>If you are having trouble uploading a photo of your receipt,
      please use the below steps, if you are still having trouble please contact us <a style="display:inline-block" href="mailto:promos@homeofpmg.com">promos@homeofpmg.com</a></p>
    <ol>
      <li>Photograph in a well-lit area</li>
      <li>Before photographing, remove any items with text or documents behind or beside the receipt</li>
      <li>Tap the screen to focus</li>
      <li>Hold your receipt steady, about 10-15cm from your smartphone camera ensuring it’s not blurry</li>
      <li>Try and capture as much of the receipt as possible- retailer and eligible product are most important to capture</li>
      <li>Click the upload button on the site to upload or drag and drop into the upload area</li>
    </ol>
  </div>

</template>

<script>
export default {
    name:'AppFaqs'
}
</script>

<style>

</style>
