import { service } from '@/utils/request'
import { RECEIPT_UPLOAD_FOLDER } from '@/settings.js'
export function getPreSignedUrl(filename) {
    return service({
        url: '/upload/' + filename,
        method: 'get',
        params: {
            folder: RECEIPT_UPLOAD_FOLDER
        }
    })
}

export function upload(url, data) {
    return service({
        url: url,
        method: 'put',
        data: data
    })
}

export function detectReceipt(data) {
    return service({
        url: '/receipt-detection/mode/sync',
        method: 'post',
        data
    })
}
