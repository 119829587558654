import { service } from '@/utils/request'

export function register(data) {
    return service({
        url: 'users',
        method: 'post',
        data
    })
}

export function getUserByUid(uid) {
    return service({
        url: 'users/' + uid,
        method: 'get'

    })
}

export function updateUserByUid(uid, data) {
    return service({
        url: 'users/' + uid,
        method: 'put',
        data
    })
}

