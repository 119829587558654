<template>
  <app-main>
    <template #headerSubtext>
      <h5>Complete the entry form below with your details and submit.</h5>
    </template>
    <entry-form />
  </app-main>
</template>

<script>
import EntryForm from '@/components/Entry/EntryForm.vue'

export default {
    name: 'AppHome',
    components: {
        EntryForm
    }
}
</script>
<style scoped lang="scss">

</style>
